@import "~config/theming/variables";

$red: #b00020;
$white: #fff;

.admin-simple-edit-modal-error-banner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid $red:
  padding: 8px;
  background-color: $red;
  color: $white;
}

.admin-simple-edit-modal-error-banner--left {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.admin-simple-edit-modal-error-banner--status {
  color: $red;
  background-color: $white;
  display: inline-block;
  padding: 4px 8px;
  margin-right: 4px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
}

.admin-simple-edit-modal-error-banner--message {
  padding: 8px;
  padding-right: 16px;
  justify-content: flex-start;
}

.admin-simple-edit-modal-error-banner--clear-action {
  padding: 6px 12px;
  justify-content: flex-end;
  cursor: pointer;
  border: 1px solid $white;
  color: $white;
  background-color: $red;
  border-radius: 3px;
  display: block;
  transition: background-color 0.2s;

  &:hover {
    background-color: lighten($red, 20%);
  }
}
