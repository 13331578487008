.fixed-table {
  height: 100%;
  overflow: auto;
  position: relative;
  font-size: 12px;
}

.fixed-table--table {
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
}

.fixed-table--header-cell {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100;
  padding: 12px;
  text-align: left;
}

.fixed-table--header-cell-border {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #ddd;
}

.fixed-table--body-cell {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.fixed-table--body-row:nth-child(odd) .fixed-table--body-cell {
  background-color: #fafafa;
}

.fixed-table--body-row:last-child .fixed-table--body-cell {
  /*border-bottom: none;*/
}
