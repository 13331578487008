.admin-checkbox {
}

.admin-checkbox--input {
  font-size: 16px;
}

.admin-checkbox--label {
  display: inline-block;
}
