.layout-panels {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.layout-panels--wrap {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
}

.layout-panels--header {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ddd;
}

.layout-panels--footer {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #ddd;
}

.layout-panels--left-panel {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #ddd;
  flex-grow: 0;
  flex-shrink: 0;
  overflow-y: auto;
}

.layout-panels--right-panel {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #ddd;
  flex-grow: 0;
  flex-shrink: 0;
  overflow-y: auto;
}

.layout-panels--mains {
  flex-grow: 1;
  overflow: auto;
}
