@import "~config/theming/variables";

.section-card-wrapper {
  padding: 16px 30px;

  &.is-bare {
    padding: 16px 0;
  }
}

.section-card--strong-badge,
.section-card--badge {
  @include semiBoldFont;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 24px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;

  font-size: 10px;
  text-transform: uppercase;
}

.section-card--strong-badge {
  background-color: $teal_or_no_deal;
  color: $dark_blue;
}

.section-card--badge {
  background-color: $blue_highlight;
  color: $white;
}
