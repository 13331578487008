@import "~config/theming/variables";

.login-controller {
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  align-items: center;
}

.login-controller--content {
  width: 460px;
  border: 1px solid #ccc;
  padding: 16px;
  background-color: #fff;

  & > * {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
