@import "~config/theming/variables";

.admin-link-to-app {
  border: none;
  background-color: transparent;
  color: $blue_accent;
  text-decoration: none;
  transition: color 0.2s;
}

.admin-link-to-app:hover:not([disabled]) {
  color: $blue_highlight;
  cursor: pointer;
  text-decoration: underline;
}
