.admin-message {
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  align-items: center;
}

.admin-message--content {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-message--icon-area {
  padding: 24px;
}

.admin-message--message {
  font-size: 20px;
  color: #888;
  text-align: center;
  line-height: 1.5;
  white-space: pre-wrap;
}

.admin-message--action-area {
  padding-top: 16px;
}
