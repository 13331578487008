.breadcrumb--breadcrumb-link {
  color: #23c2af;
  color: #888;
  text-decoration: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
}

.breadcrumb--breadcrumb-link-divider {
  color: #ddd;
  padding: 8px;
}
