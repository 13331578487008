@import "~config/theming/variables";

$regular: $light_gray;
$primary: $medium_teal;
$dangerous: #f32c52;

$textDark: #444;
$textLight: #fff;

@mixin buttonBackgroundColor($backgroundColor) {
  background-color: $backgroundColor;

  &.is-hover,
  &:hover {
    background-color: darken($backgroundColor, 10%);
  }

  &.is-active,
  &:active {
    background-color: darken($backgroundColor, 20%);
  }

  &:disabled {
    background-color: desaturate(lighten($backgroundColor, 20%), 30%);
  }
}

@mixin buttonLightText() {
  color: $textLight;
  &:disabled {
    color: darken($textLight, 5%);
  }
}

@mixin buttonDarkText() {
  color: $textDark;

  &:disabled {
    color: lighten($textDark, 50%);
  }
}

.admin-button {
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
  appearance: none;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.05);

  &.is-compact {
    font-size: 11px;
    padding: 4px 8px;
  }

  &.is-default {
    @include buttonBackgroundColor($regular);
    @include buttonDarkText();

    &:disabled {
      background-color: lighten($regular, 1%);
    }
  }

  &.is-primary {
    @include buttonBackgroundColor($primary);
    @include buttonLightText();
  }

  &.is-dangerous {
    @include buttonBackgroundColor($dangerous);
    @include buttonLightText();
  }

  &:disabled {
    cursor: default;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
}
