@import "~config/theming/variables";

.section-table--root {
  background-color: $portland_rain;
  color: #fff;
}

.section-table--table {
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
}

.section-table--header-cell {
  background-color: $portland_rain;
  text-align: left;
}

.section-table--body-cell {
  text-align: left;
  word-break: break-word;
}

.section-table--body-row {
  &:nth-child(odd) .section-table--body-cell {
    background-color: $blue_highlight;
  }
}
