.admin-link {
  /*display: inline-block;*/
  /*padding: 2px 4px;*/
  border: none;
  background-color: transparent;
  color: #00ab9c;
  /*padding: 4px;*/
  text-decoration: none;
}

.admin-link:hover:not([disabled]) {
  cursor: pointer;
  text-decoration: underline;
}
