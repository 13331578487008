.admin-select-list {
  // padding: 8px;
}

.admin-select-list--item {
  padding: 12px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  cursor: default;

  transition: background-color 0.2s;

  &:not(.is-disabled) {
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &.is-selected {
    background-color: rgba(0, 64, 128, 0.05);
  }

  &:last-child {
    border-bottom: none;
  }

  &.is-compact {
    padding: 6px;
  }
}

.admin-select-list--radio-button {
  flex-grow: 0;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

.admin-select-list--checkbox {
  flex-grow: 0;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

.admin-select-list--item-label {
  padding-left: 8px;
  flex-grow: 1;

  &.is-disabled {
    cursor: default;
    opacity: 0.6;
  }
}

.admin-select-list--item-label-text {
  font-size: 14px;
}

.admin-select-list--item-description {
  padding-top: 8px;
  color: #888;
  font-size: 12px;
}

.admin-select-list--item-badge {
  line-height: 1;
  padding: 3px 6px;
  font-size: 12px;
  border-radius: 3px;
  background-color: #ddd;
  color: #444;

  &.is-disabled {
    background-color: #eee;
    color: #888;
  }

  &.has-changed {
    background-color: #23c2af;
    color: #fff;
  }
}
