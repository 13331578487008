.admin-tabs {
  padding-left: 4px;
  padding-right: 4px;
}

.admin-tab {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: #888;
  cursor: pointer;
  padding-left: 4px;
  padding-right: 4px;
  transition: color 0.2s, border-color 0.2s;

  &:hover {
    color: #666;
  }

  &.is-active {
    color: #23c2af;
    & > .admin-tab--content {
      // color: #23c2af;
      border-bottom-color: #23c2af;
    }
  }

  &.is-compact {
  }

  &.is-disabled,
  &:disabled {
    color: #ccc;
    cursor: default;
  }
}

.admin-tab--content {
  display: inline-block;
  padding: 6px 12px;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
}
