.admin-stack {
}

.admin-stack--item {
  &.has-divider {
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }
  }
}
