.json-value {
  font-family: monospace;

  &.is-text {
    color: #444;
  }

  &.is-boolean {
    color: #573b79;
  }

  &.is-string {
    color: #d28140;
    white-space: pre-wrap;
    word-break: break-word;
  }

  &.is-id,
  &.is-number {
    color: #7e96c4;
  }

  &.is-date {
    color: #3d9970;
  }

  &.is-undefined,
  &.is-null {
    color: #f8b8b7;
  }
}
