// Basic Colors;

$white: #fff;
$black: #000;

// Worklete Colors
$teal_or_no_deal: #3cdcc9;
$dark_teal: #00ab9c;
$medium_teal: #23c2af;
$teal_accent: #bffdee;
$portland_rain: #021063;
$dark_blue: #001645;
$medium_blue: #001d58;
$blue_highlight: #07236a;
$blue_accent: #012d8a;
$safety_sand: #f8ff69;
$sunshine_yellow: #faff90;
$light_gray: #f3f3f3;
$medium_gray: #afb0b0;
$sf_fog: #97999b;

$maxWidth: 1500px;

$regularFont: "Gibson Regular", sans-serif;
$semiBoldFont: "Gibson SemiBold", sans-serif;
$compressedFont: "Interstate Regular Compressed", sans-serif; // titles

@mixin regularFont {
  font-family: "Gibson Regular", sans-serif;
}

@mixin semiBoldFont {
  font-family: "Gibson SemiBold", sans-serif;
  letter-spacing: 0.125em;
}

@mixin compressedFont {
  font-family: "Interstate Regular Compressed", sans-serif;
  letter-spacing: 0.0625em;
}
