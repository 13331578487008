.layout-bar--spacer {
  display: inline-block;
  align-self: stretch;
}

.layout-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.layout-bar--left,
.layout-bar--right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  flex-basis: auto;
  flow-grow: 2;
}

.layout-bar--left {
  justify-content: flex-start;
}

.layout-bar--right {
  justify-content: flex-end;
}
