.link-text {
  color: #3cdcc9;
  font-family: "Gibson SemiBold";
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.link-button {
  border: none;
  background-color: inherit;
  padding: 0px;
}

.link-text-disabled {
  color: #3cdcc9;
  font-family: "Gibson SemiBold";
}

.link-text:hover {
  color: #00ab9c;
}
