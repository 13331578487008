@import "~config/theming/variables";

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.placeholder--spinner {
  padding: 12px;
}

.placeholder--message {
  display: inline-block;
  padding: 12px;
  color: $white;
  @include semiBoldFont;
}

.placeholder--actions {
  margin-top: 40px;
}
