.mini-table {
  padding: 8px;
  &.is-bare {
    padding: 0;
  }
}

.mini-table--content {
  font-size: 12px;
  border: 1px solid #dadada;
  min-height: 24px;

  a,
  button {
    font-size: 12px;
  }

  &.is-bare {
    border: none;
  }
}

.mini-table--title {
  padding: 8px;
  background-color: #f0f8ff;
  border-bottom: 1px solid #dadada;
  font-size: 14px;
  font-weight: 600;

  &.is-compact {
    padding: 4px;
  }
}

.mini-table--table {
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
}

.mini-table--header-cell {
  /*background-color: #f0f8ff;*/
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #dadada;
  border-left: 1px solid #dadada;

  &.is-compact {
    padding: 6px;
  }

  &.is-empty {
    padding: 2px;
  }
}

.mini-table--header-cell:first-child {
  border-left: none;
}

.mini-table--body-cell {
  padding: 12px;
  border-bottom: 1px solid #dadada;
  border-left: 1px solid #dadada;
  text-align: left;

  &.is-compact {
    padding: 6px;
  }
}

.mini-table--body-cell:first-child {
  border-left: none;
}

.mini-table--body-row:nth-child(odd) .mini-table--body-cell {
  background-color: #fafafa;
}

.mini-table--body-row:last-child .mini-table--body-cell {
  border-bottom: none;
}
