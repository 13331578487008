.columns {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 16px;
}

.columns--column {
  flex-basis: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  border-left: 1px solid #ddd;
  padding-left: 8px;
  padding-right: 8px;

  &:first-child {
    border-left: none;
  }
}
