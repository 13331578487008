.admin-input {
  display: inline-block;
  padding: 8px;
  width: 100%;
  border-radius: 2px;
  border: 1px solid #ddd;
  font-size: 12px;

  &[disabled] {
    color: #888;
    border-color: #ddd;
    background-color: #fafafa;
  }
}
