@import "~config/theming/variables";

.section-header--supertitle,
.section-header--title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.section-header--supertitle {
  margin-bottom: 8px;
}

.section-header--supertitle-text {
  @include semiBoldFont;
  color: $teal_or_no_deal;
  text-transform: uppercase;
  font-size: 12px;
  padding-bottom: 4px;
}

.section-header--title-text {
  font-size: 24px;
  text-transform: uppercase;
  @include compressedFont;
  color: #fff;
}

.section-header--action {
  text-align: right;
}

.section-header--subtitle {
  color: $medium_gray;
  font-size: 16px;
  padding-top: 8px;
  line-height: 1.5;
}
