// configuration for Material Icons
// https://google.github.io/material-design-icons/#icon-font-for-the-web

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("../src/worklete/assets/fonts/Material_Icons/MaterialIcons-Regular.eot"); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("../src/worklete/assets/fonts/Material_Icons/MaterialIcons-Regular.woff2")
      format("woff2"),
    url("../src/worklete/assets/fonts/Material_Icons/MaterialIcons-Regular.woff")
      format("woff"),
    url("../src/worklete/assets/fonts/Material_Icons/MaterialIcons-Regular.ttf")
      format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

/* Default for the app */

@font-face {
  font-family: "Gibson Regular";
  src: url("../src/worklete/assets/fonts/Gibson_Complete_web/Gibson-Regular.woff")
    format("woff");
}

/* Links, some Subheaders */

@font-face {
  font-family: "Gibson SemiBold";
  src: url("../src/worklete/assets/fonts/Gibson_Complete_web/Gibson-SemiBold.woff")
    format("woff");
}

/* Titles */

@font-face {
  font-family: "Interstate Regular Compressed";
  src: url("../src/worklete/assets/fonts/Interstate_Regular_Compressed/Interstate_Regular_Compressed.woff")
    format("woff");
}

/* WORKLETE DEFAULTS ACROSS THE WHOLE DANG APP */

html,
body,
#root {
  min-height: 100%;
  font-family: "Gibson Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

input,
textarea,
button,
a {
  font-size: 14px;
}

button,
[href],
[tabindex] {
  &:not([tabindex="-1"]) {
    &:focus {
      &:not([data-focus-visible-added]) {
        outline: none;
      }
    }
  }
}

div {
  max-width: 100%;
}
