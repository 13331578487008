.admin-simple-edit-item {
  padding: 8px;
}

.admin-simple-edit-item--label {
  display: block;
  padding: 8px 8px 4px;
  font-weight: bold;
}

.admin-simple-edit-item--note {
  display: block;
  padding: 4px 8px 8px;
  font-size: 12px;
  color: #888;
  font-style: italic;
}
