.admin-loading-container {
  height: 100%;
  position: relative;
  overflow: auto;
}

.admin-loading-container--content {
  height: 100%;
  overflow: auto;
}

.admin-loading-container--shade {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 12;
}
