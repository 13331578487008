.vertical-nav {
  height: 100%;
  overflow: auto;
  padding: 8px;
}

.vertical-nav--link {
  margin-top: 8px;
  display: block;
  padding: 8px;
  text-decoration: none;
  border-radius: 2px;
  color: #23c2af;
}

.vertical-nav--link-subtitle {
  color: #999;
  font-size: 11px;
  line-height: 1;
  padding-top: 2px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vertical-nav--link:hover {
  background-color: #eee;
}

.vertical-nav--link.is-active {
  background-color: #23c2af;
  color: #fff;

  .vertical-nav--link-subtitle {
    color: #eee;
  }
}

.vertical-nav--divider {
  border-bottom: 1px solid #ddd;
  margin: 16px;
}
