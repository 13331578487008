.admin-pagination {
  display: inline-block;
}

.admin-pagination--count {
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  padding-right: 12px;
  color: #444;
}

.admin-pagination--buttons {
  vertical-align: middle;
}

.admin-pagination--button {
  border: 1px solid #ddd;
  background-color: #eee;
  cursor: pointer;
  margin-right: 8px;

  &:hover {
    background-color: #ddd;
  }

  &.is-last {
    margin-right: 0;
  }

  &.is-working {
    cursor: default;
  }

  &:disabled {
    background-color: transparent;
    cursor: default;
  }
}
