@import "~config/theming/variables";

.admin-menu {
}

.admin-menu--trigger-button {
}

[data-reach-menu-list].admin-menu--menu-list {
  min-width: 160px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: $light_gray;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.05);
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 4px;
  border-radius: 3px;

  [data-reach-menu-item] {
    padding: 8px 16px;
    cursor: pointer;
    user-select: none;
    // transition: background-color 0.2s, color 0.2s;

    white-space: nowrap;
    text-decoration: none;

    font-size: 13px;
    font-weight: 500;
    line-height: 1.3;
    appearance: none;

    &[disabled] {
      color: #888;
      cursor: default;
    }

    &.is-compact {
      font-size: 11px;
      padding: 4px 8px;
    }

    &[data-selected] {
      &:not([disabled]) {
        background-color: $medium_teal;
      }
      &[disabled] {
        background-color: transparent;
      }
    }
  }
}
