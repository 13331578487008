[data-reach-dialog-overlay] {
  background-color: rgba(0, 0, 0, 0.1);
}

.admin-modal {
  border: 1px solid #ccc;
  padding: 0;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.admin-modal--title {
  font-size: 18px;
  color: #444;
}

.admin-modal--content {
  padding: 12px;
}
